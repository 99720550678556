
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace('')
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index70QpIEyhK9Meta } from "/opt/build/repo/pages/ambiti/[slug]/index.vue?macro=true";
import { default as indexjJLjybovYXMeta } from "/opt/build/repo/pages/ambiti/index.vue?macro=true";
import { default as aziendaYJBhaxws2FMeta } from "/opt/build/repo/pages/azienda.vue?macro=true";
import { default as contattiuzIMrJV0pWMeta } from "/opt/build/repo/pages/contatti.vue?macro=true";
import { default as cookie_45policyZcRIsfqfIvMeta } from "/opt/build/repo/pages/cookie-policy.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as metodo_45addaNtgjWKjzfPMeta } from "/opt/build/repo/pages/metodo-adda.vue?macro=true";
import { default as partnerwG6i6RC7MtMeta } from "/opt/build/repo/pages/partner.vue?macro=true";
import { default as privacy_45policyQXTlhFuZ55Meta } from "/opt/build/repo/pages/privacy-policy.vue?macro=true";
import { default as questionario_45conformitaFvT3QBg2jSMeta } from "/opt/build/repo/pages/questionario-conformita.vue?macro=true";
import { default as index4UpFLV25ZEMeta } from "/opt/build/repo/pages/servizi/[slug]/index.vue?macro=true";
import { default as indexUDdunI5WsmMeta } from "/opt/build/repo/pages/servizi/index.vue?macro=true";
import { default as termini_45condizionizub5Zhv3xRMeta } from "/opt/build/repo/pages/termini-condizioni.vue?macro=true";
import { default as component_45stubRU5sOBTecHMeta } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubRU5sOBTecH } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "ambiti-slug___it",
    path: "/ambiti/:slug()",
    meta: index70QpIEyhK9Meta || {},
    component: () => import("/opt/build/repo/pages/ambiti/[slug]/index.vue")
  },
  {
    name: "ambiti___it",
    path: "/ambiti",
    meta: indexjJLjybovYXMeta || {},
    component: () => import("/opt/build/repo/pages/ambiti/index.vue")
  },
  {
    name: "azienda___it",
    path: "/azienda",
    meta: aziendaYJBhaxws2FMeta || {},
    component: () => import("/opt/build/repo/pages/azienda.vue")
  },
  {
    name: "contatti___it",
    path: "/contatti",
    meta: contattiuzIMrJV0pWMeta || {},
    component: () => import("/opt/build/repo/pages/contatti.vue")
  },
  {
    name: "cookie-policy___it",
    path: "/cookie-policy",
    component: () => import("/opt/build/repo/pages/cookie-policy.vue")
  },
  {
    name: "index___it",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue")
  },
  {
    name: "metodo-adda___it",
    path: "/metodo-adda",
    meta: metodo_45addaNtgjWKjzfPMeta || {},
    component: () => import("/opt/build/repo/pages/metodo-adda.vue")
  },
  {
    name: "partner___it",
    path: "/partner",
    meta: partnerwG6i6RC7MtMeta || {},
    component: () => import("/opt/build/repo/pages/partner.vue")
  },
  {
    name: "privacy-policy___it",
    path: "/privacy-policy",
    meta: privacy_45policyQXTlhFuZ55Meta || {},
    component: () => import("/opt/build/repo/pages/privacy-policy.vue")
  },
  {
    name: "questionario-conformita___it",
    path: "/questionario-conformita",
    meta: questionario_45conformitaFvT3QBg2jSMeta || {},
    component: () => import("/opt/build/repo/pages/questionario-conformita.vue")
  },
  {
    name: "servizi-slug___it",
    path: "/servizi/:slug()",
    meta: index4UpFLV25ZEMeta || {},
    component: () => import("/opt/build/repo/pages/servizi/[slug]/index.vue")
  },
  {
    name: "servizi___it",
    path: "/servizi",
    meta: indexUDdunI5WsmMeta || {},
    component: () => import("/opt/build/repo/pages/servizi/index.vue")
  },
  {
    name: "termini-condizioni___it",
    path: "/termini-condizioni",
    meta: termini_45condizionizub5Zhv3xRMeta || {},
    component: () => import("/opt/build/repo/pages/termini-condizioni.vue")
  },
  {
    name: component_45stubRU5sOBTecHMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubRU5sOBTecH
  }
]